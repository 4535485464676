@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (max-width: 768px) {
    flex-direction {
        flex-direction: column-reverse;
    }
}

.flag {
    width: 2rem;
    margin: .5rem;
}

.flag:hover {
    transform: scale(1.2);
}

.flag.selected {
    transform: scale(1.2);
}